import * as aesjs from 'aes-js';
import React from "react";


interface Window {
    decryptBytes: (inputBytes: number[], keyArray: number[], oiv: number[]) => string;
}

(window as any).decryptBytes = decryptBytes;
(window as any).attachScrollListener = attachScrollListener;
(window as any).detachScrollListener = detachScrollListener;
(window as any).getScrollY = getScrollY;
(window as any).getPageHeight = getPageHeight;

// import CryptoJS from "crypto-js";
function decryptBytes(inputBytes: number[], keyArray: number[], oiv: number[]) {
    // keyArray = new Uint8Array([...keyArray])
    // iv = new Uint8Array([...iv])
    let key = new Uint8Array(keyArray);
    let iv = new Uint8Array(oiv);
    let aesCtr = new aesjs.ModeOfOperation.cbc(key, iv);
    let decryptedBytes = aesCtr.decrypt(inputBytes);
    return aesjs.utils.utf8.fromBytes(decryptedBytes);
}

function handleScroll() {
    DotNet.invokeMethodAsync<DotNet.DotNetObject>("WebPage.Client", "GetDetailObj")
        .then(obj => {
            obj?.invokeMethodAsync("OnScroll").then();
        });
}


function attachScrollListener() {
    window.addEventListener('scroll', handleScroll)
}

function detachScrollListener() {
    window.removeEventListener('scroll', handleScroll)
};

function getScrollY() {
    // var y = window.pageYOffset;
    let y = window.scrollY + window.innerHeight;
    y = parseInt(String(y));
    return y;
}

function getPageHeight() {
    let height = document.documentElement.scrollHeight;
    height = parseInt(String(height));
    return height;
}

(window as any).onPagePicLoaded =  async (e: React.SyntheticEvent<HTMLImageElement, Event> ) => {
    try {
        // let obj = await DotNet.invokeMethodAsync<DotNet.DotNetObject>("WebPage.Client", "GetDetailObj")
        // await obj?.invokeMethodAsync("OnPagePicLoaded");
        await DotNet.invokeMethodAsync<void>('WebPage.Client', 'OnPagePicLoaded')
    } catch (e) {
        console.error(e)
    }
}

(window as any).onPagePicLoadedError =  async (e: React.SyntheticEvent<HTMLImageElement, Event> ) => {
    try {
        // let obj = await DotNet.invokeMethodAsync<DotNet.DotNetObject>("WebPage.Client", "GetDetailObj")
        // await obj?.invokeMethodAsync("OnPagePicLoadedError");
        await DotNet.invokeMethodAsync<void>('WebPage.Client', 'OnPagePicLoaded')
    } catch (e) {
        console.error(e)
    }
}

// (window as any).SearchBarEntered =  async () => {
//     let searchInput = document.getElementById("sk-search-input") as HTMLInputElement;
//     try {
//         let obj = await DotNet.invokeMethodAsync<DotNet.DotNetObject>("WebPage.Client", "GetSearchObj");
//         await obj?.invokeMethodAsync("GoSearch", searchInput.value);
//     } catch (e) {
//         console.error(e)
//     }
// }
